import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config/index';
import { useAuth } from '../context/useAuth';
import ProtectedRoute from './ProtectedRouter';

// Auth
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// Catch page
import Home from '../pages/home-page/Home';
import Profile from '../pages/profile-page/Profile';
import NotFoundError from '../pages/error-page/NotFoundError';

// AUTH VERIFICATION APP
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';
import EmployeeEmailVerification from '../pages/auth/EmployeeEmailVerification';
import ProductAndServices from '../pages/productAndServices/ProductAndServices';
import WorkingAtAuxality from '../pages/career/WorkingAtAuxality';
import AvailablePositions from '../pages/career/AvailablePositions';
import EditProduct from '../pages/productAndServices/EditProduct';
import EditPosition from '../pages/career/EditPosition';
import Locations from '../pages/career/Locations';
import EditLocation from '../pages/career/EditLocation';
import EditWorking from '../pages/career/EditWorking';
import CodeOfConduct from '../pages/career/CodeOfConduct';
import EditConduct from '../pages/career/EditConduct';
import Customers from '../pages/customers/Customers';
import NewCustomer from '../pages/customers/NewCustomer';
import Sustainability from '../pages/sustainabillity/Sustainability';
import EditSustainability from '../pages/sustainabillity/EditSustainability';
import AiPage from '../pages/ai/Ai';
import EditAiPage from '../pages/ai/EditAi';
import AiCards from '../pages/ai/AiCards';
import EditAiCard from '../pages/ai/EditAiCard';

const Router = (props) => {
  const { pathname } = props;
  const { user, refetchToken } = useAuth();
  const location = useLocation();

  const getUserTokenStatus = async () => {
    const checkUser = window.sessionStorage.getItem(USER);

    if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      return false;
    }

    const jsonToken = JSON.parse(checkUser);

    if (!jsonToken) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;

      return false;
    }

    try {
      const fetchToken = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.userCheckToken}`, null, { withCredentials: false, headers: { Authorization: `Bearer ${jsonToken.token}` } });

      if (fetchToken.status !== 200) {
        window.sessionStorage.setItem(USER, JSON.stringify(null));
        window.location.href = `/${PERMISSIONS.login}`;
      }
    } catch (err) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
    }
  };

  // TOKEN VALIDATION
  useEffect(() => {
    if (user && user.token) {
      getUserTokenStatus();
    }
  }, [user, location, refetchToken]);

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload();
    }
  }, [location]);

  // CHANGE OFFFICAL COLOR
  useLayoutEffect(() => {
    if (location.pathname.includes(`/${PERMISSIONS.login}`)) {
      document.documentElement.style.setProperty('--official-color', 'hsl(230, 76%, 59%)'); // 59%
    }
  }, [location]);

  return (
    <Routes>
      {/* public routes */}
      <Route index element={<Navigate to={`/${PERMISSIONS.login}`} replace />} />
      <Route path={`/${PERMISSIONS.login}`} element={<Login />} />
      <Route path={`/${PERMISSIONS.forgotPassword}`} element={<ForgotPassword />} />
      <Route path={`/${PERMISSIONS.passwordReset}/:id`} element={<ResetPassword />} />
      <Route path={`/${PERMISSIONS.employeeEmailVerification}/:token`} element={<EmployeeEmailVerification />} />
      {/* protected routes */}
      <Route element={<ProtectedRoute pathname={pathname} redirectPath={`/${PERMISSIONS.login}`} />}>
        <Route index path={`/${PERMISSIONS.dashboard}`} element={<Home />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.profile}`} element={<Profile />} />

        {/* PRODUCT AND SERVICES */}

        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}`} element={<ProductAndServices />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}/${PERMISSIONS.new_product}`} element={<EditProduct />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}/${PERMISSIONS.edit_product}/:id`} element={<EditProduct />} />

        {/* CAREER */}

        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.working_at_auxality}`} element={<WorkingAtAuxality />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.working_at_auxality}/${PERMISSIONS.edit_content}`} element={<EditWorking />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}`} element={<AvailablePositions />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}/${PERMISSIONS.new_position}`} element={<EditPosition />} />
        <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}/${PERMISSIONS.edit_position}/:id`} element={<EditPosition />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}`} element={<Locations />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}/${PERMISSIONS.new_location}`} element={<EditLocation />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}/${PERMISSIONS.edit_location}/:id`} element={<EditLocation />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}`} element={<CodeOfConduct />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}/:id`} element={<EditConduct />} />

        {/* ABOUT US */}
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.about_us}/${PERMISSIONS.sustainability}`} element={<Sustainability />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.about_us}/${PERMISSIONS.sustainability}/:id`} element={<EditSustainability />} />

        {/* CUSTOMERS */}
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.employees}`} element={<Customers />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.employees}/${PERMISSIONS.new_employees}`} element={<NewCustomer />} />

        {/* AI */}
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.ai}`} element={<AiPage />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.ai}/${PERMISSIONS.editAi}`} element={<EditAiPage />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}`} element={<AiCards />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}/${PERMISSIONS.new_ai_card}`} element={<EditAiCard />} />
        <Route path={`${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}/${PERMISSIONS.edit_ai_card}/:id`} element={<EditAiCard />} />

        {/* catch all */}
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
};

export default Router;
