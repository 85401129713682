import React, { useEffect, useState } from 'react';
import { Section } from '../../components/content';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';

function Sustainability() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState({});

  useEffect(() => {
    const dataFetch = async () => {
      await axios
        .get(`${SERVER_URL}/sustainability-policy`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) setData(res.data);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Failed to fetch data',
            placement: 'bottomLeft',
          });
        });
    };
    dataFetch();
  }, []);

  return (
    <>
      <Section className="section__wrapper working-at-auxality">
        <header className="section__header">
          <div>
            <h1>Sustainability</h1>
            <a target="_blank" href="http://frontend.ax.concordsofttest.com/sustainability">
              Sustainability
            </a>
          </div>
          <button className="btn btn-primary aiContainer" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.about_us}/${PERMISSIONS.sustainability}/${PERMISSIONS.edit_sustainability}`)}>
            Edit Content
          </button>
        </header>

        <div className="aiContainer" style={{ fontSize: '30px' }}>
          <h1>{data?.title}</h1>
        </div>

        <div style={{ padding: '0px 20px' }}>
          <h4 className="aiContent" dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
      </Section>
    </>
  );
}

export default Sustainability;
