import { PERMISSIONS } from './permissions';

const menuItems = (props) => {
  const { handleLogout, onHide } = props;
  const menu = [
    {
      _id: '',
      label: 'Employees',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.employees}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/employees-icon.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Pages',
      path: ``,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/pages-icon.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function () {
        // onHide(false);
      },
      children: [
        {
          _id: '',
          label: 'AI',
          path: ``,
          group: 'account',
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          hideArrow: true,
          dataCy: 'sidebar-grocery-profile',
          visible: true,
          onClick: function () {
            // onHide(false);
          },
          children: [
            {
              _id: '',
              label: 'AI - preview',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.ai}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
            {
              _id: '',
              label: 'AI cards',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
          ],
        },
        {
          _id: '',
          label: 'Product and services',
          path: ``,
          group: 'account',
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          hideArrow: true,
          dataCy: 'sidebar-grocery-profile',
          visible: true,
          onClick: function () {
            // onHide(false);
          },
          children: [
            {
              _id: '',
              label: 'All products',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
          ],
        },
        {
          _id: '',
          label: 'Career',
          path: ``,
          group: 'account',
          type: 'anchor',
          icon: '',
          expanded: true,
          protected: false,
          hideArrow: true,
          dataCy: 'sidebar-grocery-profile',
          visible: true,
          onClick: function () {
            // onHide(false);
          },
          children: [
            {
              _id: '',
              label: 'Working at Auxality',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.working_at_auxality}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                // onHide(false);
              },
            },
            {
              _id: '',
              label: 'Available positions',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                // onHide(false);
              },
            },
            {
              _id: '',
              label: 'Code of Conduct',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
          ],
        },
        {
          _id: '',
          label: 'About us',
          path: ``,
          group: 'account',
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          hideArrow: true,
          dataCy: 'sidebar-grocery-profile',
          visible: true,
          onClick: function () {
            // onHide(false);
          },
          children: [
            {
              _id: '',
              label: 'Sustainability',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.about_us}/${PERMISSIONS.sustainability}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
            {
              _id: '',
              label: 'Locations',
              path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}`,
              type: 'anchor',
              icon: '',
              expanded: false,
              protected: false,
              dataCy: 'sidebar-grocery-attributes',
              visible: true,
              onClick: () => {
                onHide(false);
              },
            },
          ],
        },
      ],
    },
    {
      _id: '',
      label: 'Profile',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile-icon.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function () {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Logout',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return menu;
};

export { menuItems };
