const PERMISSIONS = {
  // ATTRIBUTES
  attributes: 'attributes',
  createAttributes: 'create_attributes',
  readAttributes: 'read_attributes',
  updateAttributes: 'update_attributes',
  deleteAttributes: 'delete_attributes',

  // ROLES
  roles: 'roles',
  createRoles: 'create_roles',
  readRoles: 'read_roles',
  updateRoles: 'update_roles',
  deleteRoles: 'delete_roles',

  // EMPLOYEES
  employees: 'employees',

  // ACTIVITY
  activity: 'activity',
  readActivity: 'read_logs',

  // PROFILE
  profile: 'profile',
  readProfile: 'read_profile',
  updateProfile: 'update_profile',
  deleteProfile: 'delete_profile',

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: 'employee-email-verification',

  // AUTH TYPE
  authType: 'basic',

  // SIGN IN PATH
  signInPath: 'dashboard',

  // LOGIN
  login: 'login',

  // FORGOT PASSWORD
  forgotPassword: 'forgot-password',

  // PASSWORD RESET
  passwordReset: 'password-reset',

  // DASHBOARD
  dashboard: 'admin',

  // CREATE NEW PRODUCT
  new_product: 'new-product',

  // EDIT PRODUCT
  edit_product: 'edit-product',

  // PRODUCT AND SERVICES
  product_and_services: 'product-and-services',

  text_manager: 'text-manager',
  web_manager: 'web-manager',
  report_manager: 'report-manager',
  advisory_services: 'advisory-services',
  services_platform_management: 'services-platform-management',

  // CAREER
  career: 'career',
  working_at_auxality: 'working-at-auxality',
  available_positions: 'available-positions',
  locations: 'locations',
  code_of_conduct: 'employee-code-of-conduct',

  // EDIT WORKING CONTENT
  edit_content: 'edit-content',

  // AVAILABLE POSITIONS
  new_position: 'new-position',

  // EDIT POSITION
  edit_position: 'edit-position',

  // NEW LOCATION
  new_location: 'new-location',

  // EDIT LOCATION
  edit_location: 'edit-location',

  // CUSTOMERS
  customers: 'customers',
  new_customer: 'new-customer',
  new_employees: 'new-employee',

  // ABOUT US
  about_us: 'about-us',
  sustainability: 'sustainability',
  edit_sustainability: 'edit-sustainability',

  // AI
  ai: 'ai',
  editAi: 'edit-ai',
  ai_cards: 'ai-cards',
  new_ai_card: 'new-ai-card',
  edit_ai_card: 'edit-ai-card',
};

const USER = 'user';

const SUPER_ADMIN = 'Super Admin';

export { PERMISSIONS, USER, SUPER_ADMIN };
