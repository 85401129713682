import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
const apiKey = '2dy4s1m6ni0auky1txvg4ah9zgovb0w2hay0ydtud6tahix4';
export const ContentDescription = (props) => {
  const { label, setForm, description } = props;
  const getTextValue = (value) => {
    if (value !== description) {
      setForm(value);
    }
  };
  const initialOptions = {
    height: 500,
    menubar: true,
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt',
    language: 'en',
    plugins: ['table ', 'lists ', 'anchor ', 'autolink', 'advlist ', 'link', 'image ', 'charmap ', 'preview '],
    toolbar: 'fullscreen preview print | undo redo | sizeselect | fontselect |  fontsizeselect| bold italic backcolor |  \
     alignleft aligncenter alignright alignjustify tindent_bttn | tfecha_bttn | \
     bullist numlist outdent indent | removeformat | restoredraft wordcount',
  };

  return (
    <div className="custom-select custom-rich-editor">
      <span className="label">
        <b>{label}:</b>
      </span>
      <div className="select select-result">
        <Editor apiKey={apiKey} onEditorChange={(newValue, editor) => getTextValue(editor.getContent())} init={{ ...initialOptions }} value={description} />
      </div>
    </div>
  );
};
