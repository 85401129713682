import React from 'react';
import { Section } from '../../components/content';
import { Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';

const initialValues = {
  role: 'editor',
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  password: null,
  status: 'WAITING FOR ACTIVATION',
};

function NewCustomer() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const [form] = Form.useForm();

  const handleStatus = (value) => {};

  const statusOptions = [
    {
      value: 'ACTIVE',
      label: 'ACTIVE',
    },
    {
      value: 'INACTIVE',
      label: 'INACTIVE',
    },
    {
      value: 'DELETED',
      label: 'DELETED',
    },
    {
      value: 'WAITING_FOR_ACTIVATION',
      label: 'WAITING_FOR_ACTIVATION',
    },
  ];

  const handleFinish = async (values) => {
    try {
      await axios.post(`${SERVER_URL}/users`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.employees}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Section className="section__wrapper customers">
        <header className="section__header">
          <h1>New Employee</h1>

          <button className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.employees}`)}>
            All Employees
          </button>
        </header>

        <div className="content-main">
          <Form form={form} initialValues={initialValues} onFinish={async (values) => handleFinish(values)} style={{ padding: '0 20px' }}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
                {
                  pattern: /^[\d\s-]+$/,
                  message: 'Please enter a valid mobile phone number!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="status" label="Status">
              <Select onChange={handleStatus} options={statusOptions} />
            </Form.Item>

            <div style={{ textAlign: 'right' }}>
              <button className="btn btn-primary" style={{ padding: '0px 20px', marginTop: '20px' }}>
                Create
              </button>
            </div>
          </Form>
        </div>
      </Section>
    </>
  );
}

export default NewCustomer;
