import React, { Fragment } from 'react';
import { Section } from '../../components/content';
import { Button, Popconfirm, Table, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';

function ProductAndServices() {
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/product-pages`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}`, user },
        });
        if (res && res.data) {
          setData(res.data);
        }
      } catch (error) {
        console.error(error);
        // Notification
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomLeft',
        });
      }
    };
    dataFetch();
  }, [triggerReload]);

  const handleInitialProducts = async () => {
    try {
      await axios.get(`${SERVER_URL}/product-pages-initial-create`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setTriggerReload((prev) => !prev);
      notification.success({
        message: 'Successfully created',
        placement: 'bottomLeft',
      });
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: 'Failed to create',
        placement: 'bottomLeft',
      });
    }
  };

  const deleteProduct = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/product-pages/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setTriggerReload((prev) => !prev);
      notification.success({
        message: 'Successfully deleted',
        placement: 'bottomLeft',
      });
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: 'Failed to delete',
        placement: 'bottomLeft',
      });
    }
  };
  const onCancel = () => {
    return;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <div>
              <EditOutlined onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}/${PERMISSIONS.edit_product}/${record._id}`)} />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Popconfirm title="Are you sure to delete this product?" onConfirm={() => deleteProduct(record._id)} onCancel={onCancel} okText="Yes" cancelText="No">
              <DeleteOutlined style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Section className="section__wrapper products-and-services">
        <header className="section__header">
          <div>
            <h1>All Products</h1>
            <a target="_blank" href="http://frontend.ax.concordsofttest.com/products-and-services">
              All Products
            </a>
          </div>

          <div className="right">
            <button disabled={data && data.length > 0} className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={handleInitialProducts}>
              Create initial products
            </button>
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}/${PERMISSIONS.new_product}`)}>
              New Product
            </button>
          </div>
        </header>

        <Table
          className="products-table"
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </Section>
    </>
  );
}

export default ProductAndServices;
