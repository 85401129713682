import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { EyeFilled } from '@ant-design/icons';
import { EyeInvisibleFilled } from '@ant-design/icons';
import { useAuth } from '../../context/useAuth';
import { Section, ContainerFluid, Wrapper } from '../../components/content';

const Login = () => {
  const { login, user } = useAuth();
  const [loginLoader, setLoginLoader] = useState(false);

  const onFinish = async (form) => {
    setLoginLoader(true);
    const loginReturn = await login(form);
    loginReturn === false && setLoginLoader(false);
  };

  const onFinishFailed = (err) => {
    setLoginLoader(false);
  };

  if (user) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <Section className="section__login">
      <ContainerFluid className="container-fluid">
        <Wrapper className="card-wrapper form-wrapper">
          <div className="d-flex justify-center items-center mb-4 gap-x-1">
            <img className="login-circle" src="/assets/icons/login.svg" alt="Login" />
            <h1 className="mb-0">Login</h1>
          </div>

          <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <label className="label" htmlFor="email">
              Email:
            </label>
            <Form.Item name="email" data-cy="login-email" rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input placeholder="Email" id="email" />
            </Form.Item>

            <label className="label" htmlFor="password">
              Password:
            </label>
            <Form.Item name="password" data-cy="login-password" rules={[{ required: true, message: 'Please enter your   password!' }]}>
              <Input.Password iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)} placeholder="Password" id="password" />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0, paddingTop: '20px' }}>
              {!loginLoader ? (
                <Button type="primary" data-cy="login-submit" className="btn btn-block btn-primary btn-no-focus" htmlType="submit">
                  Login
                </Button>
              ) : (
                <Button type="primary" className="btn btn-block btn-primary btn-no-focus" htmlType="button">
                  Logging in...
                </Button>
              )}
            </Form.Item>

            <Form.Item style={{ paddingTop: '40px' }}>
              <Link to="/forgot-password" data-cy="login-forgot" className="link link-primary text-center d-block full-height">
                <span>Forgot password?</span>
              </Link>
            </Form.Item>
          </Form>
        </Wrapper>
      </ContainerFluid>
    </Section>
  );
};

export default Login;
