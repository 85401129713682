import React, { useEffect, useState } from 'react';
import { Section } from '../../components/content';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { Form, Input } from 'antd';
import { ContentDescription } from '../../components/textboxEditor/Content';
import { SERVER_URL } from '../../config';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useAuth } from '../../context/useAuth';

function EditAiCard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [form] = useForm();
  const { user } = useAuth();
  const { token } = user;

  useEffect(() => {
    if (id) {
      const dataFetch = async () => {
        await axios
          .get(`${SERVER_URL}/ai-cards/${id}`)
          .then((res) => {
            if (res.status === 200) {
              form.setFieldsValue(res.data);
              setContent(res.data?.content);
            }
          })
          .catch((err) => console.error(err));
      };
      dataFetch();
    }
  }, [id]);

  const onHandleFinish = async (values) => {
    const backendValues = { ...values, content };
    const method = id ? 'patch' : 'post';
    const route = id ? `${SERVER_URL}/ai-cards/${id}` : `${SERVER_URL}/ai-cards`;

    await axios[method](route, backendValues, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <Section className="section__wrapper products-and-services">
        <header className="section__header">
          <div>
            <h1>{id ? 'Edit' : 'Create'} AI Card</h1>
          </div>

          <div className="right">
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}`)}>
              All AI Cards
            </button>
          </div>
        </header>

        <Form form={form} onFinish={onHandleFinish} style={{ padding: '30px 20px' }}>
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please fill the title.' }]}>
            <Input />
          </Form.Item>

          <ContentDescription description={content} label={'Content'} setForm={setContent} />

          <div style={{ textAlign: 'right', marginTop: '15px' }}>
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="submit">
              {id ? 'Save' : 'Create'}
            </button>
          </div>
        </Form>
      </Section>
    </>
  );
}

export default EditAiCard;
