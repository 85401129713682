import React from 'react';
import { Section } from '../../components/content';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';

function AiPage() {
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/ai-page`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}`, user },
        });
        if (res && res.data) {
          setData(res.data);
          setBenefits(res.data.benefits);
        }
      } catch (error) {
        console.error(error);
        // Notification
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomLeft',
        });
      }
    };
    dataFetch();
  }, []);

  return (
    <>
      <Section className="section__wrapper products-and-services">
        <header className="section__header" style={{ marginBottom: '30px' }}>
          <div>
            <h1>AI Page</h1>
            <a target="_blank" href="http://frontend.ax.concordsofttest.com/ai">
              AI website
            </a>
          </div>

          <div className="right">
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.ai}/${PERMISSIONS.editAi}`)}>
              Edit Ai
            </button>
          </div>
        </header>

        <div style={{ padding: '0px 20px', marginBottom: '24px', fontSize: '30px' }}>
          <h4>{data?.title?.toUpperCase()}</h4>
          <h4>{data?.h1}</h4>
        </div>

        <div className="aiContainer">
          <h4 className="aiContent" dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>

        <div className="aiContainer">
          <h4 className="aiContent" dangerouslySetInnerHTML={{ __html: data?.keyFeatures }} />
        </div>

        <div className="aiContainer">
          <h4>{data?.cardContent}</h4>
        </div>

        <div>
          {benefits && benefits.length > 0 ? (
            <div className="aiContainer">
              {benefits.map((item, idx) => {
                return (
                  <h4>
                    {idx + 1}. {item?.label}
                  </h4>
                );
              })}
            </div>
          ) : null}
        </div>
      </Section>
    </>
  );
}

export default AiPage;
