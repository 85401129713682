const AXIOS_API_CALL = {
  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: 'employee-email-verification',

  // LOGIN
  login: 'login',

  // FORGOT PASSWORD
  forgotPassword: 'forgot-password',

  // PASSWORD RESET
  passwordReset: 'password-reset',

  // USER CHECK TOKEN
  userCheckToken: 'users/check-token',
};

export { AXIOS_API_CALL };
