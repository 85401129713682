import React, { useEffect, useState } from 'react';
import { Section } from '../../components/content';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';

function WorkingAtAuxality() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState({});

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/working-at`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(res.data);
      } catch (error) {
        console.error(error);
        // Notification
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomLeft',
        });
      }
    };
    dataFetch();
  }, []);

  return (
    <>
      <Section className="section__wrapper working-at-auxality">
        <header className="section__header">
          <div>
            <h1>Working at Auxality</h1>
            <a href="http://frontend.ax.concordsofttest.com/career/working-at-auxality" target="_blank">
              Working at Auxality
            </a>
          </div>
          <button className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.working_at_auxality}/${PERMISSIONS.edit_content}`)}>
            Edit Content
          </button>
        </header>

        <div style={{ padding: '0px 20px', marginBottom: '24px', fontSize: '30px' }}>
          <h1>{data?.title}</h1>
        </div>

        <div style={{ padding: '0px 20px' }}>
          <h4 dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
      </Section>
    </>
  );
}

export default WorkingAtAuxality;
