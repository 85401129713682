import React, { useEffect, useState } from 'react';
import { Section } from '../../components/content';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';

function CodeOfConduct() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState({});

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/conduct`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(res.data);
      } catch (error) {
        console.error(error);
        // Notification
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomLeft',
        });
      }
    };
    dataFetch();
  }, []);

  return (
    <Section className="section__wrapper working-at-auxality">
      <header className="section__header">
        <div>
          <h1>Employee code of conduct</h1>
          <a target="_blank" href="http://frontend.ax.concordsofttest.com/career/employee-code-of-conduct">
            Employee code of conduct
          </a>
        </div>
        <button className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}/${PERMISSIONS.edit_content}`)}>
          Edit Content
        </button>
      </header>

      <div style={{ padding: '0px 20px', marginBottom: '24px', fontSize: '30px' }}>
        <h1>{data?.title}</h1>
      </div>

      <div style={{ padding: '0px 20px' }}>
        <h4 dangerouslySetInnerHTML={{ __html: data?.content }} />
      </div>
    </Section>
  );
}

export default CodeOfConduct;
