import React, { useState } from 'react';
import { Section } from '../../components/content';
import { Checkbox, Form, Input, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';
import { ContentDescription } from '../../components/textboxEditor/Content';

const initialValues = {
  title: null,
  description: null,
  link: null,
  open: null,
};

function EditPosition() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const { token } = user;
  const [checkbox, setCheckbox] = useState(null);
  const [form] = Form.useForm();
  const [content, setContent] = useState('');

  useEffect(() => {
    if (id) {
      const dataFetch = async () => {
        await axios
          .get(`${SERVER_URL}/available-positions/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              form.setFieldsValue(res.data);
              setContent(res.data.description);
              setCheckbox(res.data.open)
            }
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: 'Failed to fetch data',
              placement: 'bottomLeft',
            });
          });
      };
      dataFetch();
    }
    setCheckbox(true)
  }, [id]);

  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckbox(checked);
  };

  const onFinishForm = async (values) => {
    const dataBackend = { ...values, open: checkbox, description: content };
    const method = id ? 'patch' : 'post';
    const route = id ? `${SERVER_URL}/available-positions/${id}` : `${SERVER_URL}/available-positions`;
    try {
      await axios[method](route, dataBackend, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      notification.success({
        message: `Successfully ${id ? 'updated' : 'created'} position.`,
        placement: 'bottomLeft',
      });
      navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}`);
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: `Failed to ${id ? 'update' : 'create'} position.`,
        placement: 'bottomLeft',
      });
    }
  };

  return (
    <>
      <Section className="section__wrapper edit-position">
        <header className="section__header">
          <h1>{id ? 'Edit' : 'Create'} Position</h1>
          <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.available_positions}`)}>
            All Positions
          </button>
        </header>

        <Form form={form} initialValues={initialValues} style={{ padding: '30px 20px' }} onFinish={async (values) => onFinishForm(values)}>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <ContentDescription label="Position description" description={content} setForm={setContent} />

          <Form.Item
            name="link"
            label="Link"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <label htmlFor="status">Open: </label>
          <Checkbox name="open" checked={checkbox} onChange={(e) => handleCheckbox(e)} id="status" />

          <div style={{ textAlign: 'right' }}>
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="submit">
              {id ? 'Save' : 'Create'}
            </button>
          </div>
        </Form>
      </Section>
    </>
  );
}

export default EditPosition;
