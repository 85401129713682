import React from 'react';
import { Section } from '../../components/content';
import { Form, Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { ContentDescription } from '../../components/textboxEditor/Content';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';

const initialForm = {
  title: null,
};

function EditConduct() {
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const { user } = useAuth();
  const { token } = user;
  const [form] = Form.useForm();

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await axios.get(`${SERVER_URL}/conduct`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        });
        form.setFieldsValue(res.data);
        setContent(res.data.content);
      } catch (error) {
        console.error(error);
        // Notification
      }
    };
    dataFetch();
  }, []);

  const onFinishForm = async (values) => {
    const backendValues = { ...values, content };

    try {
      await axios.post(`${SERVER_URL}/conduct`, backendValues, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      notification.success({
        message: 'Successfully updated',
        placement: 'bottomLeft',
      });
      navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}`);
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: 'Failed to update',
        placement: 'bottomLeft',
      });
    }
  };

  return (
    <>
      <Section className="section__wrapper edit-content">
        <header className="section__header">
          <h1>Employee code of conduct</h1>
          <button className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.code_of_conduct}`)}>
            View Content
          </button>
        </header>

        <div className="content-main">
          <Form form={form} initialValues={initialForm} onFinish={async (values) => onFinishForm(values)} style={{ padding: '0px 20px' }}>
            <Form.Item
              name="title"
              label="Header Title"
              rules={[
                {
                  required: true,
                  message: 'Please fill out the field',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <ContentDescription label={'Content'} description={content} setForm={setContent} />

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="submit">
                Update
              </button>
            </div>
          </Form>
        </div>
      </Section>
    </>
  );
}

export default EditConduct;
