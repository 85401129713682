import React, { useState, useEffect } from 'react';
import { Section } from '../../components/content';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import { PERMISSIONS } from '../../utils/permissions';
import { Table } from 'antd';
import axios from 'axios';
import { useAuth } from '../../context/useAuth';

function Customers() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created at',
      render: (value, record) => {
        const date = new Date(record.createdAt);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${SERVER_URL}/users`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        })
        .catch((err) => console.error(err));
    };
    fetchData();
  }, []);

  return (
    <>
      <Section className="section__wrapper customers">
        <header className="section__header">
          <h1>All Employees</h1>
          <button className="btn btn-primary" style={{ padding: '0px 20px' }} onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.employees}/${PERMISSIONS.new_employees}`)}>
            New Employee
          </button>
        </header>

        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </Section>
    </>
  );
}

export default Customers;
