import React, { useEffect, useState } from 'react';
import { Section } from '../../components/content';
import { Popconfirm, Table, notification } from 'antd';
import { PERMISSIONS } from '../../utils/permissions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';

function Locations() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const { token } = user;
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${SERVER_URL}/locations`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) setData(res.data);
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: 'Failed to fetch data',
            placement: 'bottomLeft',
          });
        });
    };
    fetchData();
  }, [triggerReload]);

  const deleteProduct = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/locations/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setTriggerReload((prev) => !prev);
      notification.success({
        message: 'Successfully deleted',
        placement: 'bottomLeft',
      });
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: 'Failed to delete',
        placement: 'bottomLeft',
      });
    }
  };
  const onCancel = () => {
    return;
  };

  const columns = [
    {
      title: 'City',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Edit',
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <div>
              <EditOutlined onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}/${PERMISSIONS.edit_location}/${record._id}`)} />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Delete',
      align: 'center',
      render: (value, record) => {
        return (
          <div>
            <Popconfirm title="Are you sure to delete this product?" onConfirm={() => deleteProduct(record._id)} onCancel={onCancel} okText="Yes" cancelText="No">
              <DeleteOutlined style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Section className={'section__wrapper locations'}>
        <header className="section__header">
          <h1>Available Locations</h1>
          <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}/${PERMISSIONS.new_location}`)}>
            New Location
          </button>
        </header>

        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </Section>
    </>
  );
}

export default Locations;
