import React from 'react';
import { Section } from '../../components/content';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, Form, Input, Upload, message, notification } from 'antd';
import { useState } from 'react';
import { PERMISSIONS } from '../../utils/permissions';
import axios from 'axios';
import { useAuth } from '../../context/useAuth';
import { SERVER_URL } from '../../config';
import { ContentDescription } from '../../components/textboxEditor/Content';
import { UploadOutlined } from '@ant-design/icons';

const initialForm = {
  name: null,
  description: null,
  image: null,
};

function EditLocation() {
  const { id } = useParams();
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [contactUsContent, setContactUsContent] = useState('');
  const [videoUpload, setVideoUpload] = useState('');
  const [video, setVideo] = useState('');

  useEffect(() => {
    if (id) {
      const dataFetch = async () => {
        await axios
          .get(`${SERVER_URL}/locations/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              form.setFieldsValue(res.data);
              setContent(res.data.description);
              setContactUsContent(res.data.contentDesc);
              setVideoUpload(res.data?.video?._id);
              setVideo(res.data?.video);
            }
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: 'Failed to fetch data',
              placement: 'bottomLeft',
            });
          });
      };
      dataFetch();
    }
  }, [id]);

  const onFinishForm = async (values) => {
    const backendValues = { ...values, description: content, contentDesc: contactUsContent, video: videoUpload };
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/locations/${id}` : `${SERVER_URL}/locations`;

      await axios[method](route, backendValues, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}`);
      notification.success({
        message: `Successfully ${id ? 'updated' : 'created'} location.`,
        placement: 'bottomLeft',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: `Failed to ${id ? 'update' : 'create'} location.`,
        placement: 'bottomLeft',
      });
    }
  };

  return (
    <>
      <Section className={'section__wrapper locations'}>
        <header className="section__header">
          <h1>{id ? 'Edit' : 'Create'} location</h1>
          <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.career}/${PERMISSIONS.locations}`)}>
            All Locations
          </button>
        </header>

        <Form form={form} initialValues={initialForm} style={{ padding: '0px 20px' }} onFinish={async (values) => onFinishForm(values)}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <ContentDescription label="Location description" description={content} setForm={setContent} />

          <ContentDescription label="Contact us description" description={contactUsContent} setForm={setContactUsContent} />

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Button onClick={() => setVideoUpload(null)} danger>
            Remove Video
          </Button>

          <Form.Item style={{ marginBottom: '15px' }} name="video" label="Upload video">
            <Upload
              name="video"
              maxCount={1}
              headers={{ Authorization: `Bearer ${token}` }}
              listType="picture-card"
              action={`${SERVER_URL}/upload-s3`}
              onChange={(e) => {
                const { status } = e.file;
                if (status === 'done') {
                  setVideoUpload(e.file?.response?.video?._id);
                  setVideo(e.file?.response?.video);
                } else if (status === 'error') {
                  message.error(`${e.file.name} file upload failed.`);
                  if (e.file.response.message) {
                  }
                }
              }}
            >
              <UploadOutlined />
              Upload
            </Upload>
          </Form.Item>

          {videoUpload ? (
            <video autoPlay={true} playsInline={true} width={300} height={200}>
              <source type={video?.mimetype} src={video?.location} />
              <source type="video/mp4" src={video?.location} />
              <source type="video/webp" src={video?.location} />
            </video>
          ) : null}

          <div style={{ textAlign: 'right', marginBottom: '30px' }}>
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="submit">
              {id ? 'Save' : 'Create'}
            </button>
          </div>
        </Form>
      </Section>
    </>
  );
}

export default EditLocation;
