import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import Overlay from '../overlay/Overlay';
import { Wrapper, Aside } from '../content';
import Navbar from '../navbar/Navbar';
import { PERMISSIONS } from '../../utils/permissions';
import { menuItems } from '../../utils/navigations';

const Sidebar = (props) => {
  const { onHide, onToggle, active, className } = props;
  const { logout } = useAuth();
  const location = useLocation();

  // LOGOUT
  const handleLogout = () => {
    logout();
    onHide(false);
  };

  useEffect(() => {
    active ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden');
  }, [active]);

  const navbarProps = {
    location: location,
  };

  const menuProps = {
    onHide: onHide,
    handleLogout: handleLogout,
  };

  // Menus
  const itemsMenu = menuItems(menuProps);

  return (
    <>
      <Aside className={`gl__sidebar gl__sidebar--left gl__sidebar--layout ${active ? 'isActive' : ''} ${className !== undefined ? className : ''}`}>
        <Wrapper className="logo">
          <Link to={`/${PERMISSIONS.dashboard}`} className="link d-block" onClick={() => onHide(false)} data-cy="sidebar-logo">
            <img className="logo-brand" alt="Auxality" src="/assets/icons/auxality_Logo_neg_RGB.png" />
          </Link>
        </Wrapper>

        <Navbar className="navbar" {...navbarProps} menus={{ menu: itemsMenu }} />
      </Aside>

      <Overlay onToggle={onToggle} active={active} />
    </>
  );
};

export default Sidebar;
