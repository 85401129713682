import React, { useState, useEffect } from 'react';
import { Section } from '../../components/content';
import { Button, notification, Form, Input, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from '../../utils/permissions';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { useAuth } from '../../context/useAuth';
import { ContentDescription } from '../../components/textboxEditor/Content';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

const initialForm = {
  url: null,
  title: null,
  benefits: null,
  h1: null,
};

function EditProduct() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token } = user;
  const { id } = useParams();
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [imageUpload, setImageUpload] = useState('');

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const res = await axios.get(`${SERVER_URL}/product-pages/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });
          if (res && res.data) {
            form.setFieldsValue(res.data);
            setContent(res.data?.content);
            setImageUpload(res.data?.image);
          }
        } catch (error) {
          console.error(error);
          // Notification
          notification.error({
            message: 'Failed to fetch data',
            placement: 'bottomLeft',
          });
        }
      };
      fetchData();
    } else {
      console.warn('create page');
    }
  }, [id]);

  const onFinishForm = async (values) => {
    values.image = values.image?.file;
    const backendValues = { ...values, content, image: imageUpload };
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/product-pages/${id}` : `${SERVER_URL}/product-pages`;

      await axios[method](route, backendValues, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      // Notification
      notification.success({
        message: `Successfully ${id ? 'updated' : 'created'} product.`,
        placement: 'bottomLeft',
      });
      navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}`);
    } catch (error) {
      console.error(error);
      // Notification
      notification.error({
        message: `Failed to ${id ? 'update' : 'create'} product.`,
        placement: 'bottomLeft',
      });
    }
  };

  return (
    <>
      <Section className="section__wrapper edit-product">
        <header className="section__header">
          <h1>{id ? 'Edit' : 'New'} Product</h1>
          <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.product_and_services}`)}>
            All Products
          </button>
        </header>

        <Form style={{ padding: '30px 20px' }} form={form} initialValues={initialForm} onFinish={async (values) => onFinishForm(values)} name="productsForm">
          <Form.Item
            name="url"
            label="Url ( /<end of url> )"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="h1"
            label="Subtitle"
            rules={[
              {
                required: true,
                message: 'Please fill out this field.',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <ContentDescription description={content} label={'Content'} setForm={setContent} />

          <Form.Item name="cardContent" label="Card content" style={{ margin: '30px 0px' }}>
            <Input.TextArea />
          </Form.Item>

          <Form.List name="benefits">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <Form.Item label={index === 0 ? 'Benefits' : ''} required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input benefit label or delete this field.',
                          },
                        ]}
                        noStyle
                        name={[index, 'label']}
                      >
                        <Input
                          placeholder="Benefit label"
                          style={{
                            width: '60%',
                          }}
                        />
                      </Form.Item>

                      {fields.length > 0 ? <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} style={{ marginLeft: '10px', fontSize: '20px' }} /> : null}
                    </Form.Item>
                  );
                })}

                <Form.Item>
                  <Button
                    type="default"
                    onClick={() => add()}
                    style={{
                      width: '20%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Benefit
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Button onClick={() => setImageUpload(null)} danger>
            Remove Image
          </Button>

          <Form.Item style={{ marginBottom: '15px' }} name="image" label="Upload image">
            <Upload
              name="image"
              maxCount={1}
              headers={{ Authorization: `Bearer ${token}` }}
              listType="picture-card"
              action={`${SERVER_URL}/image-upload`}
              onChange={(e) => {
                const { status } = e.file;
                if (status === 'done') {
                  setImageUpload(e.file?.response?.image?.localPath);
                } else if (status === 'error') {
                  message.error(`${e.file.name} file upload failed.`);
                  if (e.file.response.message) {
                  }
                }
              }}
            >
              <UploadOutlined />
              Upload
            </Upload>
          </Form.Item>
          {imageUpload ? <img width={100} height={100} src={`${imageUpload}`} /> : null}

          <div style={{ textAlign: 'right' }}>
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" type="submit">
              {id ? 'Save' : 'Create'}
            </button>
          </div>
        </Form>
      </Section>
    </>
  );
}

export default EditProduct;
