import React, { useEffect, useState } from 'react';
import { PERMISSIONS } from '../../utils/permissions';
import { Popconfirm, Table } from 'antd';
import { Section } from '../../components/content';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/useAuth';

function AiCards() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const { token } = user;
  const [triggerReload, setTriggerReload] = useState(false);
  useEffect(() => {
    const dataFetch = async () => {
      await axios
        .get(`${SERVER_URL}/ai-cards`)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    dataFetch();
  }, [triggerReload]);

  const deleteProduct = async (id) => {
    await axios
      .delete(`${SERVER_URL}/ai-cards/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => setTriggerReload((prev) => !prev))
      .catch((err) => console.error(err));
  };
  const onCancel = () => {
    return;
  };

  const columns = [
    {
      title: 'Card Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => {
        return <span>{value.slice(0, 10)}</span>;
      },
    },
  ];

  columns.push({
    title: 'Edit',
    key: 'edit',
    align: 'center',
    render: (value, record) => {
      return (
        <div>
          <div>
            <EditOutlined onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}/${PERMISSIONS.edit_ai_card}/${record._id}`)} />
          </div>
        </div>
      );
    },
  });
  columns.push({
    title: 'Delete',
    key: 'delete',
    align: 'center',
    render: (value, record) => {
      return (
        <div>
          <Popconfirm title="Are you sure to delete this product?" onConfirm={() => deleteProduct(record._id)} onCancel={onCancel} okText="Yes" cancelText="No">
            <DeleteOutlined style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </div>
      );
    },
  });

  return (
    <>
      <Section className="section__wrapper products-and-services">
        <header className="section__header">
          <div>
            <h1>AI Cards</h1>
            <a target="_blank" href="http://frontend.ax.concordsofttest.com/ai">
              AI Cards
            </a>
          </div>

          <div className="right">
            <button style={{ padding: '0px 20px' }} className="btn btn-primary" onClick={() => navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.ai_cards}/${PERMISSIONS.new_ai_card}`)}>
              New AI Card
            </button>
          </div>
        </header>

        <Table
          className="products-table"
          columns={columns}
          dataSource={data}
          rowKey={(record) => record._id}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </Section>
    </>
  );
}

export default AiCards;
